import React from 'react'
import PropTypes from 'prop-types'

const GridItem = ({ icon, title, description }) => (
  <li className="mt-10 first:mt-0 sm:mt-0">
    <div className="flex">
      <div className="flex-shrink-0">
        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
          {icon}
        </div>
      </div>
      <div className="ml-4">
        <h5 className="text-lg leading-6 font-semibold text-gray-900">{title}</h5>
        <p className="mt-2 text-base leading-6 text-gray-500">
          {description}
        </p>
      </div>
    </div>
  </li>
)

export default GridItem

GridItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired
}