import React from 'react'

const GridWrapper = ({ children }) => (
  <div className="mt-16">
    <ul className="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
      {children}
    </ul>
  </div>
)

export default GridWrapper