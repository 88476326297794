import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GridItemWithImage as GridItem } from '../../Global/Content/OffsetGrid'

const DublinAirport = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "dublin-airport.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 630, maxHeight: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <GridItem
      title="Dublin Airport"
      description="Situated just 10km north from the capital city, Dublin Airport is Ireland's busiest international airport. Dublin Airport has a wide network of routes throughout Continental Europe, US and Canada."
      sources={image.childImageSharp.fluid}
      imageAlt="Airport transfers from Dublin Airport with Chauffeur Me."
    />
  )
}

export default DublinAirport