import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GridItemWithImage as GridItem } from '../../Global/Content/OffsetGrid'

const BelfastInternational = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "belfast-international-airport.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 630, maxHeight: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <GridItem
      title="Belfast International Airport"
      description="The main international gateway to Northern Ireland, Belfast International Airport has trans-Atlantic flights, with flights to New York and Vancouver as well as flights to many major European cities."
      sources={image.childImageSharp.fluid}
      imageAlt="Airport transfers from Belfast International with Chauffeur Me."
    />
  )
}

export default BelfastInternational