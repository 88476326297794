import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GridItemWithImage as GridItem } from '../../Global/Content/OffsetGrid'

const IrelandWest = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "ireland-west-knock-airport.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 630, maxHeight: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <GridItem
      title="Ireland West Airport Knock"
      description="Ireland West Airport Knock is located in County Mayo. The airport is located just outside the town of Knock."
      sources={image.childImageSharp.fluid}
      imageAlt="Airport transfers from Ireland West Airport Knock with Chauffeur Me."
    />
  )
}

export default IrelandWest