import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GridItemWithImage as GridItem } from '../../Global/Content/OffsetGrid'

const BelfastCity = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "belfast-city-airport-transfers.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 630, maxHeight: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <GridItem
      title="Belfast City Airport"
      description="The George Best Belfast City Airport is found in the very heart of the city providing routes from Belfast to cities in the UK and Europe."
      sources={image.childImageSharp.fluid}
      imageAlt="Airport transfers from Belfast City Airport with Chauffeur Me."
    />
  )
}

export default BelfastCity