import React from 'react'

const Wrapper = ({ children }) => (
  <div className="py-24 bg-gray-50">
    <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
      {children}
    </div>
  </div>
)

export default Wrapper