import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/Layout'
import LeftWithImage from '../../components/Global/Hero/LeftWithImage'
import { YellowButton } from '../../components/Global/Button'
import SEO from '../../components/Global/SEO'
import { OffsetGridWrapper } from '../../components/Global/Content/OffsetGrid'
import { BelfastInternational, DublinAirport, BelfastCity, CorkAirport, ShannonAirport, IrelandWest } from '../../components/Services/Airports'
import { Centered2x2GridWrapper, GridWrapper, GridItem } from '../../components/Global/Content/Centered2x2Grid'
import SectionTitle from '../../components/Global/SectionTitle'
import { EmojiHappy, User, Sparkles, ClipboardCheck } from '../../components/Global/Icons'

const AirportTransfersPage = ({ location }) => {
  const { seoImg, airport } = useStaticQuery(graphql`
    query {
      seoImg: file(relativePath: { eq: "airport-transfers.jpg" }) {
        childImageSharp {
          fixed(quality: 90, width: 1200, height: 630) {
            src
          }
        }
      }
      airport: file(relativePath: { eq: "airport-transfers.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 900, maxHeight: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>

      <SEO
        title="Executive Airport Transfers | Belfast, Dublin, Shannon, Cork, Ireland West, Derry"
        description="Complete chauffeur service for airport transfers for all of Ireland's main airports, including Belfast International, Belfast City and Dublin International airports. Our driver will meet and greet you at the airport in one of our chauffeur-driven cars."
        image={seoImg.childImageSharp.fixed.src}
        pathname={location.pathname}
      />

      <LeftWithImage
        image={airport.childImageSharp.fluid}
        imageAlt="Executive Airport Transfers"
      >
        <div className="text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
          Services
        </div>
        <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
        Executive Airport
          <br className="hidden md:inline" />
          <span className="text-yellow-300">Transfers</span>
        </h2>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
          Complete chauffeur service for airport transfers for all of Ireland's main airports, including Belfast International, Belfast City and Dublin International airports. Our driver will meet and greet you at the airport in one of our chauffeur-driven cars.
        </p>
        <YellowButton to="/book-now" className="inline-block mt-8">Book Now</YellowButton>
      </LeftWithImage>

      <Centered2x2GridWrapper>
        <SectionTitle 
          title="Specialists in 24hr Airport Transfers"
          text="Our luxury executive chauffeured cars are always punctual and professional and we operate a fully flexible service should your travel itinerary change."
        />
        <GridWrapper>
          <GridItem
            title="Meet and Greet"
            description="Your chauffeur will be waiting for you directly at the gate with a personalized sign, to assist you with your luggage and escort you to your waiting car."
            icon={<EmojiHappy />}
          />
          <GridItem
            title="Professional Drivers"
            description="Our hand-picked and fully trained chauffeurs are our company ambassadors. They are a reflection of our company’s chauffeur service standards and beliefs."
            icon={<User />}
          />
          <GridItem
            title="Luxury Fleet"
            description="Our fleet of vehicles is ideal for luxury airport transfers, providing comfort, style and safety for every decerning passenger. Our cars are carefully selected for maximum interior comforts and style."
            icon={<Sparkles />}
          />
          <GridItem
            title="Reliable Service"
            description="Chauffeur Me take pride in offering a reliable service to meet any expectations. We ensure that all our drivers arrive on time, tracking your flight for delays or early arrival."
            icon={<ClipboardCheck />}
          />
        </GridWrapper>
      </Centered2x2GridWrapper>

      <OffsetGridWrapper title="Luxury Transfers From Irish Airports">
        <BelfastInternational />
        <DublinAirport />
        <BelfastCity />
        <CorkAirport />
        <ShannonAirport />
        <IrelandWest />
      </OffsetGridWrapper>

    </Layout>
  )
}

export default AirportTransfersPage