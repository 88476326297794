import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GridItemWithImage as GridItem } from '../../Global/Content/OffsetGrid'

const ShannonAirport = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "shannon-airport.jpeg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 630, maxHeight: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <GridItem
      title="Shannon Airport"
      description="Located on the west coast of Ireland, Shannon Airport is a gateway for flights to Ireland from the US and several European destinations."
      sources={image.childImageSharp.fluid}
      imageAlt="Airport transfers from Shannon Airport with Chauffeur Me."
    />
  )
}

export default ShannonAirport